import React, { useState } from "react"
import styled from "styled-components"
import ReactStars from "react-rating-stars-component"
import { Colors } from "../colors/Colors"
import MainButton from "./main-button"
import { addReview } from "../services/parse-bridge"
import Loader from "react-loader-spinner"
import { navigate } from "gatsby"

export default function ReviewForm() {
  const [rating, setRating] = useState(null)
  const [loading, setLoading] = useState(false)

  const onSubmit = async ev => {
    ev.preventDefault()
    setLoading(true)
    const elementsArray = [...ev.target.elements]
    const formData = elementsArray.reduce((acc, elem) => {
      if (elem.id) {
        acc[elem.id] = elem.value
      }

      return acc
    }, {})

    const reviewData = { ...formData, rating }
    const review = await addReview(reviewData)

    if (review.success) {
      alert("Thanks!")
    }
    setLoading(false)
    navigate("/leave-a-review")
  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "50vh",
        }}
      >
        <Loader type="Oval" color={Colors.gold} height={30} width={30} />
      </div>
    )
  }

  return (
    <Form onSubmit={onSubmit}>
      <div style={{ marginBottom: 40 }}>
        <ReactStars
          count={5}
          onChange={val => setRating(val)}
          size={30}
          activeColor={Colors.gold}
        />
      </div>
      <Input placeholder="Your name (Optional)" id="name" name="name" />
      <TextareaInput
        placeholder="Write a comment..."
        id="comment"
        name="comment"
        rows={5}
      />
      <MainButton type="submit" style={{ width: "100%" }}>
        Submit Review
      </MainButton>
    </Form>
  )
}
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  padding: 20px;
`

const Input = styled.input`
  border: 0px solid #f3f3f3;
  border-bottom: 2px solid #f3f3f3;
  font-size: 16px;
  font-family: "Articulat";
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  margin-bottom: 15px;
  padding: 15px;
  width: 100%;
  border-radius: 2px;

  ::placeholder {
    font-size: 14px;
  }

  :focus {
    outline: none;
    border-bottom: 2px solid ${Colors.gold};
  }
`
const TextareaInput = styled.textarea`
  border: 0px solid #f3f3f3;
  border-bottom: 2px solid #f3f3f3;
  font-size: 16px;
  font-family: "Articulat";
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  margin-bottom: 15px;
  width: 100%;
  padding: 15px;
  border-radius: 2px;

  ::placeholder {
    font-size: 14px;
  }

  :focus {
    outline: none;
    border-bottom: 2px solid ${Colors.gold};
  }
`
