import React from 'react'
import styled from 'styled-components';
import Layout from '../components/layout';
import ReviewForm from '../components/review-form';

export default function LeaveAReviewPage() {
    return (
        <Layout noNav>
            <Wrapper>
                <Container>
                    <Logo src={require('../images/logo_alt@2x.png')}/>
                    <h2>Leave a review</h2>
                    <ReviewForm />
                </Container>
            </Wrapper>
        </Layout>
    )
}

const Wrapper = styled.div`
  
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
`;
const Logo = styled.img`
    width: 100px;
    font-weight: 400;
    font-size: 22px;
`;